<template>
    <ion-page>
        <ion-content>
            <div class="main">
                <div class="topBackground">
                    <ion-text class="cWhite ion-text-center menuTitleFont">
                        <h2 class="ion-no-margin menuTitleFont" style="font-size: 24px;">Complètez votre profil et passez votre entretien !</h2>
                    </ion-text>
                </div>
                <div class="maxWidth">
                    <ion-card class="ion-margin-top ion-margin-bottom">
                        <ion-card-header>
                            <ion-card-title>On aimerait en savoir plus sur vous : </ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-item>
                                <ion-label position="floating">Prénom</ion-label>
                                <ion-input v-model="info.firstName"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">Nom</ion-label>
                                <ion-input v-model="info.lastName"></ion-input>
                            </ion-item>


                        </ion-card-content>
                    </ion-card>

                    <ion-card class="ion-margin-vertical">
                        <ion-card-header>
                            <ion-card-title>Je suis :</ion-card-title>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-list>
                                <ion-radio-group :value="info.status" v-model="info.status">
                                    <div>
                                      <ion-item>
                                        <ion-radio :value="999" mode="md" slot="end"></ion-radio>
                                        <ion-label>Titulaire de Pharmacie</ion-label>
                                      </ion-item>
                                    </div>
                                    <div :key="grade.id" v-for="grade in grades">
                                        <ion-item>
                                            <ion-radio :value="grade.id" mode="md" slot="end"></ion-radio>
                                            <ion-label>{{grade.title}} intérimaire</ion-label>
                                        </ion-item>
                                    </div>
                                </ion-radio-group>
                            </ion-list>
                          <ion-list v-if="info.status == 9">
                                <ion-radio-group v-model="info.yearStudy">
                                    <ion-list-header>
                                        <ion-label class="yearStudy">
                                            <h3>Peux-tu nous préciser l'année 😇</h3>
                                        </ion-label>
                                    </ion-list-header>

                                    <ion-item>
                                        <ion-label>3ème année</ion-label>
                                        <ion-radio mode="md" :value="3"></ion-radio>
                                    </ion-item>

                                    <ion-item>
                                        <ion-label>4ème année</ion-label>
                                        <ion-radio mode="md" :value="4"></ion-radio>
                                    </ion-item>

                                    <ion-item>
                                        <ion-label>5ème année</ion-label>
                                        <ion-radio mode="md" :value="5"></ion-radio>
                                    </ion-item>

                                    <ion-item>
                                        <ion-label>6ème année</ion-label>
                                        <ion-radio mode="md" :value="6"></ion-radio>
                                    </ion-item>
                                </ion-radio-group>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>
                  <ion-card class="ion-margin-vertical" v-if="info.status !== 999 && info.status !== null">
                  <ion-card-header>
                    <ion-card-title>Votre CV Alloopharma</ion-card-title>
                  </ion-card-header>
                    <ion-item v-if="!isPharmacist">
                                <ion-label position="floating">Lien du CV Alloopharma</ion-label>
                                <ion-input v-model="info.cv_link" @ionBlur="validateCVLink"></ion-input>
                                <ion-text v-if="cvLinkError" color="danger">{{ cvLinkError }}</ion-text>
                    </ion-item>

                  </ion-card>
                  <ion-card class="ion-margin-vertical" v-if="info.status !== 999 && info.status !== null">
                    <ion-card-header>
                      <ion-card-title>Aidez-nous à trouver les missions qui sont faites pour vous ⬇️</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                      <ion-item slot="start" >
                        <ion-label class="label" position="floating">Quels sont tes modes de transports ?</ion-label>
                        <ion-select
                            interface="alert"
                            cancel-text="Annuler"
                            :multiple="true"
                            v-model="info.transportAvailable">
                          <ion-select-option value="transport">Transports en commun</ion-select-option>
                          <ion-select-option value="car">Voiture</ion-select-option>
                        </ion-select>
                      </ion-item>

                      <ion-item slot="start" >
                        <ion-label class="label" position="floating">Quelle est votre activité actuelle ?</ion-label>
                        <ion-select
                            interface="alert"
                            cancel-text="Annuler"
                            v-model="info.currentActivity">
                          <ion-select-option value="cdd">CDD</ion-select-option>
                          <ion-select-option value="cdi">CDI</ion-select-option>
                          <ion-select-option value="interim">Intérim</ion-select-option>
                          <ion-select-option value="nothing">Aucune</ion-select-option>
                        </ion-select>
                      </ion-item>

                      <ion-item slot="start" >
                        <ion-label class="label" position="floating" v-if="info.status === 10">Quelle est votre CFA ?</ion-label>
                        <ion-label class="label" position="floating" v-else-if="info.status === 9">Quelle est votre fac ?</ion-label>
                        <ion-label class="label" position="floating" v-else>Quelle a été votre fac diplômante ?</ion-label>
                        <ion-input v-model="info.school" type="text" />
                      </ion-item>

                      <ion-item slot="start" v-if="info.status !== 9">
                        <ion-label class="label" position="floating">En quelle année avez-vous été diplômé•e ?</ion-label>
                        <ion-select
                            interface="alert"
                            cancel-text="Annuler"
                            v-model="info.yearGraduate">
                          <ion-select-option
                              v-for="index in 100" :key="index"
                              :value="index + 1950"
                          >
                            {{ index + 1950 }}
                          </ion-select-option>
                        </ion-select>
                      </ion-item>
                    </ion-card-content>
                  </ion-card>
                    <div class="error-message" v-if="flashMessages.length > 0">
                        <ion-card :key="msg" v-for="msg in flashMessages">
                            {{ msg }}
                        </ion-card>
                    </div>
                    <div class="ion-justify-content-center ion-margin-bottom">
                        <ion-button class="validate" color="success" v-if="shouldDisplayValidate"
                                    v-on:click="validate()">
                            VALIDER
                        </ion-button>
                        <div class="w-50" lines="none" style="margin: auto" v-if="shouldDisplayCalendly">
                            <p>Clique sur ce bouton pour réserver ton entretien directement avec nous 😇</p>
                            <BookingCalendly/>
                        </div>
                        <ion-button @click="goDashboard" class="ion-margin-bottom" color="light"
                                    v-if="!shouldDisplayValidate">
                            TABLEAU DE BORD
                        </ion-button>
                    </div>
                </div>
                <div class="footerBackground" v-if="!isDesktop">
                    <img
                            class="ion-margin-bottom logoFooter"
                            src="../../../public/assets/icon/logo-alloo-pharma.svg"
                    />
                </div>
                <div class="footerNotMobile" v-else>
                    <img
                            class="ion-margin-bottom logoFooter"
                            src="../../../public/assets/icon/alloopharma-logo.png"
                    />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
  import store from '@/store'
  import router from '@/router'
  import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption,
    IonText
  } from '@ionic/vue';
  import {getGrades, updateProfile} from "@/services/api";
  import BookingCalendly from "../../components/BookingCalendly";

  export default {
    name: 'InfoProfil',
    components: {
      IonSelect, IonSelectOption,
      BookingCalendly, IonContent, IonPage, IonButton, IonRadioGroup, IonRadio,
      IonLabel, IonItem, IonListHeader, IonText, IonInput, IonList
    },
    data() {
      return {
        flashMessages: [],
        shouldDisplayCalendly: false,
        shouldDisplayValidate: true,
        cvLinkError: '',
        info: {
          firstName: null,
          lastName: null,
          birthday: "2020-01-01",
          gender: "autre",
          status: null,
          yearStudy: null,
          transportAvailable: [],
          currentActivity: null,
          yearGraduate: null,
          school: null,
          cv_link: null,
        }
      }
    },
    mounted() {

      const user = store.getters.user;
      console.log(user)
      this.info.firstName = user.firstName;
      this.info.lastName = user.lastName;
      this.info.birthday = user.birthday;
      this.info.gender = user.gender;
      this.info.cv_link = user.cvLink;
      this.info.status = user.gradeId || user.statusId;
      this.info.yearStudy = user.yearStudies;
      this.setShouldDisplayValidate();
      this.setShouldDisplayCalendly();
    },
    computed: {
      grades() {
        return store.getters.grades
      },
      isDesktop() {
        return store.getters.isDesktop;
      },
    },
    methods: {
      setShouldDisplayCalendly() {
        if (store.getters.acceptationStatus === 'En attente de validation par Alloopharma' && !store.getters.isCalendlyMeetTaken) {
          this.shouldDisplayCalendly = true;
        }
      },
      setShouldDisplayValidate() {
        if (store.getters.acceptationStatus !== "En attente de complétion du profil" || store.getters.isPharmacist) {
          this.shouldDisplayValidate = false;
        }
      },
      removeErrorMsg() {
        setTimeout(() => this.flashMessages = [], 10000);
      },
      validate() {
        this.onUpdateProfile();
      },
      validateCVLink() {
        const regex = /https:\/\/alloopharma\.co\/recrutement\/candidat\/\d+\/\?key=\w+/;
        if (!regex.test(this.info.cv_link)) {
          this.cvLinkError = 'Lien invalide. exemple : https://alloopharma.co/recrutement/candidat/?key=rbtf3123ras.';
          return false
        } else {
          this.cvLinkError = '';
          return true
        }
      },
      onUpdateProfile() {
        const {
          firstName, lastName, birthday, gender, status, yearStudy, transportAvailable, currentActivity, yearGraduate,
            school, cv_link
        } = this.info;
        window.$crisp.push(["set", "user:nickname", [`${firstName} ${lastName}`]]);
        const isPharmacist = status === 999;
        let params = { firstName, lastName , birthday, gender, isPharmacist };
        if (!isPharmacist) {
          if(!this.validateCVLink()) return
          params = {
            ...params, userAttributes: {
              yearStudies: yearStudy, gradeId: status, transportAvailable, currentActivity, yearGraduate, school, cv_link
            }
          };
        }
        if (!firstName || !lastName || !status) {
          this.removeErrorMsg();
          this.flashMessages = ["Vous n'avez pas renseigné tous les champs 😅"];
          return;
        }
        updateProfile(params).then(() => {
          this.setShouldDisplayCalendly();
          this.setShouldDisplayValidate();
          this.goDashboard()
        }).catch((e) => {
          this.flashMessages = e.response.data.errors.full_messages;
          this.removeErrorMsg()
        });
      },
      goDashboard() {
        router.push("/tableau-bord")
      }
    },
    ionViewWillEnter() {
      getGrades()
      window.$crisp.push(["do", "chat:show"])
    }
  }
</script>
<style scoped>
    * {
        color: black;
        font-family: SFProTextMedium;
    }

    h1 {
        padding: 50px;
        margin-top: -20px
    }

    ion-label {
        margin: 5px;
    }

    .yearStudy {
        margin-top: 20px;
        margin-bottom: 20px;
    }



    h2 {
        padding: 50px;
        color: white;
    }

    h3 {
        font-size: 16px;
    }

    ion-card-content {
        padding: 0px
    }

</style>
